<template>
  <div>
    <div class="row">
      <div class="col-12 col-sm-6">
        <b-form-group label="Número / Série">
          <b-input v-model="obj.numero" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-6">
        <b-form-group label="Tipo">
          <b-select :options="['Entrada', 'Saída']" v-model="obj.tipo" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-10 pr-sm-1">
        <b-form-group label="Chave">
          <b-input v-model="obj.chave" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-2 pl-sm-0">
        <b-form-group label="UF">
          <b-input v-model="obj.uf" v-mask="'XX'" max="2" style="text-transform:uppercase" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-7 pr-sm-0">
        <b-form-group label="Razão Social">
          <b-input v-model="obj.razao_social" />
        </b-form-group>
      </div>
      <div class="col-12 col-sm-5 pl-sm-1">
        <b-form-group label="CPF/CNPJ">
          <b-input v-model="obj.cnpj" v-mask="['###.###.###-##','##.###.###/####-##']" />
        </b-form-group>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    value: {
      type: Object,
      default() {
        return {
          numero: "",
          chave: "",
          cnpj: "",
          razao_social: "",
          data: "",
          tipo: "Entrada",
          uf: "SP"
        };
      }
    }
  },
  mounted() {},
  data() {
    return {
      obj: this.value
    };
  },
  watch: {
    value: function(){
        this.obj = this.value;
    },
    obj: {
      deep: true,
      handler() {
        this.handleInput();
      }
    }
  },
  computed: {},
  methods: {
    handleInput() {
      this.$emit("input", this.obj);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>