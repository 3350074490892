<template>
  <div class="pl-0 pr-0 pl-md-3 pr-md-3">
    <div
      class="text-center p-4"
      v-if="loading"
    >
      <b-spinner></b-spinner>
      <br />
      Carregando...
    </div>

    <div
      class="row pl-0 pr-0 pl-md-3 pr-md-3"
      v-if="!loading"
    >
      <div class="col-12">
        <div class="row">
          <div class="col-12 col-md-8 col-xl-6">
            <div class="row">
              <div class="col-12 col-sm-6">
                <label>Código</label>
                <b-input
                  :readonly="true"
                  v-model="obj.id"
                  style="max-width: 100px"
                ></b-input>
              </div>
              <div
                class="col-12 col-sm-6"
                v-if="obj.tipo && obj.tipo == 'V'"
              >
                <b-form-group label="Competência">
                  <b-input
                    type="date"
                    v-model="obj.dt_competencia"
                  />
                </b-form-group>
              </div>

              <div class="col-4 text-center">
                <!-- <b-dropdown size="lg" variant="link" no-caret>
                  <template #button-content>
                    <b-icon-upc-scan class="mr-1" />
                    <span v-if="!$store.state.isMobile">Leitura</span>
                    <span class="sr-only">Search</span>
                  </template>
                  <b-dropdown-item @click.prevent="lerBoleto">
                    <i class="fa fa-barcode" /> Ler Código de Barras de Boleto
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="lerBoleto">
                    <i class="fa fa-file-upload" /> Carregar XML NFe
                  </b-dropdown-item>
                </b-dropdown> -->
                <!-- <b-form-group label="Vencimento">
                  <v-date-picker v-model="obj.dt_vencimento">
                    <template v-slot="{ inputValue, inputEvents }">
                      <input
                        class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300 text-center"
                        :value="inputValue"
                        v-on="inputEvents"
                      />
                    </template>
                  </v-date-picker>
                </b-form-group> -->
              </div>

              <div class="col-12 mb-3 mt-2">
                <select-fornecedor
                  :fornecedorId="obj.fornecedor_id"
                  @change="(ev) => (obj.fornecedor_id = ev)"
                  :showTitle="true"
                />
              </div>

              <div class="col-12 mb-3 mt-2">
                <select-centro-custo
                  :custoId="obj.cod_custo"
                  @change="(ev) => (obj.cod_custo = ev)"
                  :showTitle="true"
                  :show-manager="true"
                />
              </div>
              <div class="col-12 mb-3 mt-2">
                <select-plano-contas
                  :planoId="obj.plano_id"
                  @change="(ev) => (obj.plano_id = ev)"
                  :showTitle="true"
                  :show-manager="true"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-4 col-xl-6 pl-0 pr-0">
            <div class="mb-3">
              <b>Observações</b>
              <b-textarea v-model="obj.obs"></b-textarea>
            </div>
            <div
              class="accordion"
              role="tablist"
            >
              <b-card
                no-body
                class="mb-1"
              >
                <b-card-header
                  header-tag="header"
                  class="p-1"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-1
                    variant="primary"
                    ><i class="fa fa-file-invoice-dollar" /> Nota
                    Fiscal</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-1"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <nota-fiscal-form v-model="obj.nota_fiscal" />
                  </b-card-body>
                </b-collapse>
              </b-card>
              <b-card
                no-body
                class="mb-1"
              >
                <b-card-header
                  header-tag="header"
                  class="p-1"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-2
                    variant="primary"
                    ><b-icon-file-pdf /> Anexos</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-2"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <file-upload
                      ref="flUP"
                      v-model="obj.fotos"
                    />
                  </b-card-body>
                </b-collapse>
              </b-card>

              <b-card
                no-body
                class="mb-1"
              >
                <b-card-header
                  header-tag="header"
                  class="p-1"
                  role="tab"
                >
                  <b-button
                    block
                    v-b-toggle.accordion-3
                    variant="primary"
                    ><i class="fa fa-barcode" /> Informações para
                    Pagamento</b-button
                  >
                </b-card-header>
                <b-collapse
                  id="accordion-3"
                  accordion="my-accordion"
                  role="tabpanel"
                >
                  <b-card-body>
                    <div
                      class="row"
                      v-if="obj && obj.adicional && obj.adicional.pagamento"
                    >
                      <div class="col-12 col-sm-8 col-md-6">
                        <b-form-group label="Tipo">
                          <v-select
                            :options="['Boleto', 'Fatura', 'PIX', 'TED']"
                            v-model="obj.adicional.pagamento.tipo"
                          ></v-select>
                        </b-form-group>
                      </div>
                      <div class="col-12">
                        <b-form-group label="Observação / Detalhes">
                          <b-input
                            v-model="obj.adicional.pagamento.obs"
                          ></b-input>
                        </b-form-group>
                      </div>
                    </div>
                  </b-card-body>
                </b-collapse>
              </b-card>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <b-form-group>
              <label><b-icon-recycle></b-icon-recycle> Tipo de Despesa</label>
              <v-select
                :options="[
                  { value: 'F', text: 'FIXA' },
                  { value: 'V', text: 'PARCELADO' },
                ]"
                label="text"
                :reduce="(i) => i.value"
                v-model="obj.tipo"
                :clearable="false"
                @blur="calcualrParcelasFixas"
              ></v-select>
            </b-form-group>
          </div>
        </div>
        <div
          class="row"
          v-if="obj.tipo == 'F'"
        >
          <div class="col-3">
            <b-form-group>
              <label>Tipo de Recorrencia</label>
              <v-select
                v-model="obj.tipo_calculo_parcela"
                :required="true"
                :clearable="false"
                :multiple="false"
                :options="[
                  'Mensal',
                  'Diaria',
                  'Semanal',
                  'Quinzenal',
                  'Bimestral',
                  'Trimestral',
                  'Semestral',
                  'Anual',
                ]"
                @change="calcualrParcelasFixas"
                @input="calcualrParcelasFixas"
              />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group>
              <label>Inicia em</label>
              <b-input
                type="date"
                v-model="obj.dt_vencimento"
                @blur="calcualrParcelasFixas"
              />
            </b-form-group>
          </div>

          <div class="col">
            <b-form-group>
              <label>Termina em</label>
              <div class="d-flex">
                <b-input
                  type="date"
                  v-model="obj.dt_expira"
                  v-show="obj.nao_expira !== 1"
                  @blur="calcualrParcelasFixas"
                  style="max-width: 200px"
                />
                <b-checkbox
                  class="ml-2"
                  v-model="obj.nao_expira"
                  :value="1"
                  switch
                  @change="calcualrParcelasFixas"
                  >Nunca Termina</b-checkbox
                >
              </div>
            </b-form-group>
          </div>
          <div class="col-6">
            <div class="row">
              <div class="col-6">
                <b-form-group label="Valor">
                  <b-input
                    v-model="obj.vl_total"
                    pattern="\d*"
                    v-money="moneyMask"
                    @blur="calcualrParcelasFixas"
                  />
                </b-form-group>
              </div>
              <div class="col-6">
                <b-form-group>
                  <label>Prazos de Pagamento</label>
                  <div class="d-flex">
                    <b-input
                      type="number"
                      v-model="obj.prazo_pagamento"
                      min="0"
                      max="95"
                      autocomplete="off"
                      pattern="\d*"
                      @blur="calcualrParcelasFixas"
                    ></b-input>
                    <span
                      class="ml-1"
                      style="font-size: 10px"
                      >dias após faturamento</span
                    >
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>

        <div
          class="row"
          v-if="obj.tipo == 'V'"
        >
          <div class="col-6">
            <b-form-group label="Parcelas">
              <b-input
                v-model="obj.parcelas"
                type="number"
                class="text-center"
                min="1"
                max="95"
                autocomplete="off"
                pattern="\d*"
              />
            </b-form-group>
          </div>
          <div class="col-6">
            <b-form-group label="Valor">
              <b-input
                v-model="obj.vl_total"
                pattern="\d*"
                v-money="moneyMask"
              />
            </b-form-group>
          </div>

          <div class="col-12">
            <hr />
            <b>Parcelas</b>
            <div
              class="w-100 text-left pt-2 pb-2 d-flex justify-content-between"
            >
              <div
                class="mr-3"
                style="flex: 1"
              >
                <b>Data Vencimento Incial</b>
                <b-input
                  type="date"
                  v-model="obj.dt_vencimento"
                />
              </div>
              <v-select
                v-model="tipoCalculoParcela"
                class="mt-4 mr-3"
                :required="true"
                :clearable="false"
                :multiple="false"
                style="min-width: 160px; flex: 1"
                :options="[
                  'Mensal',
                  'Diaria',
                  'Semanal',
                  'Quinzenal',
                  'Bimestral',
                  'Trimestral',
                  'Semestral',
                  'Anual',
                ]"
              />

              <b-btn
                variant="success"
                size="sm"
                @click="recalcularParcelas()"
                style="flex: 1; max-height: 60px"
                ><i class="fa fa-sync" /> Re-calcular</b-btn
              >
            </div>

            <hr />
            <div class="d-flex justify-content-between font-weight-bold">
              <div class="text-left">Total Parcelas</div>

              <span>{{
                obj.parcelasObj
                  ? obj.parcelasObj.reduce((ret, vl) => {
                      ret += formatValorReal(vl.valor);
                      return ret;
                    }, 0)
                  : 0 | currencyMask
              }}</span>
            </div>
          </div>
          <div class="col-12 mt-3"></div>
        </div>
      </div>
      <div v-if="obj.tipo == 'F'">
        <b-tabs v-model="tabParc">
          <b-tab
            title="Próximos Pagamentos"
            :value="0"
          ></b-tab>
          <b-tab
            title="Pagamentos Realizados"
            :value="1"
          ></b-tab>
        </b-tabs>
      </div>
      <div
        v-if="loadingCalculoParcelas"
        class="p-4 m-4 text-center"
      >
        <b-spinner></b-spinner>
      </div>
      <b-table
        v-if="!loadingCalculoParcelas"
        small
        :fields="[
          {
            key: 'parcela',
            label: 'Parcela',
            class: obj.tipo == 'F' ? 'd-none' : '',
          },
          { key: 'dt_vencimento', label: 'Vencimento' },
          {
            key: 'dt_competencia',
            label: 'Competencia',
            // class: obj.tipo == 'V' ? 'd-none' : '',
          },
          { key: 'valor', label: 'Valor' },
          { key: 'bx', label: 'Baixa' },
        ]"
        :items="parcelasLista"
        stacked="md"
        class="b-table-stacked-sm"
        ref="tbParcelas"
      >
        <template #cell(parcela)="row">
          <b-input
            type="number"
            class="text-center"
            min="1"
            v-model="row.item.parcela"
            :disabled="row.item.situacao && row.item.situacao != 'A'"
          />
        </template>
        <template #cell(dt_vencimento)="row">
          <b-input
            type="date"
            :disabled="row.item.situacao && row.item.situacao != 'A'"
            v-model="row.item.dt_vencimento"
          />
        </template>
        <template #cell(dt_competencia)="row">
          <b-input
            type="date"
            :disabled="row.item.situacao && row.item.situacao != 'A'"
            v-model="row.item.dt_competencia"
          />
        </template>

        <template #cell(valor)="row">
          <b-input
            class="text-center"
            min="1"
            v-model="row.item.valor"
            v-money="moneyMask"
            style="min-width: 120px"
            :disabled="row.item.situacao && row.item.situacao != 'A'"
          />
          <div
            class="text-right"
            style="font-size: 10px; color: red"
            v-if="row.item.valor_pago"
          >
            Pago: {{ row.item.valor_pago || "--" }}<br />
            Em Aberto:
            {{
              (formatValorReal(row.item.valor) -
                formatValorReal(row.item.valor_pago))
                | currencyMask
            }}
          </div>
        </template>

        <template #cell(bx)="row">
          <div class="text-center">
            <b-checkbox
              switch
              v-model="row.item.baixar"
              :disabled="row.item.situacao === 'T'"
            ></b-checkbox>
            <div
              v-if="row.item.baixar"
              class="ml-2 card text-left"
              style="font-size: 12px; min-width: 260px"
            >
              <div class="card-body">
                <b-checkbox
                  style="font-size: 12px"
                  v-model="row.item.baixa.conciliado"
                  :value="1"
                  :unchecked-value="0"
                >
                  Conciliado
                </b-checkbox>
                <b>Data Baixa</b>
                <b-input
                  type="date"
                  style="font-size: 12px"
                  v-model="row.item.baixa.data"
                />
                <div class="mt-2">
                  <b>Carteira</b>
                  <select-carteira
                    @change="(ct) => (row.item.baixa.carteira_id = ct)"
                  ></select-carteira>
                </div>
                <div class="mt-2">
                  <b>Tipo Histórico</b>
                  <select-tipo-h-istorico
                    :tipoHistoricoID="initialTipoHistorico"
                    @change="(ct) => (row.item.baixa.tipo_historico_id = ct)"
                  ></select-tipo-h-istorico>
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-table>

      <div class="col-12 pt-5 mt-3 text-right">
        <hr />
        <b-btn
          variant="success"
          class="mr-1"
          @click="salvar"
          :disabled="saving"
        >
          <b-spinner
            small
            v-if="saving"
          />
          <i
            class="fa fa-save"
            v-else
          />
          Salvar
        </b-btn>
        <b-btn
          variant="danger"
          @click="$emit('close')"
        >
          <i class="fa fa-ban" /> Cancelar
        </b-btn>
      </div>
    </div>
    <b-modal
      id="modal-ler-boleto"
      size="lg"
      class="modal-full"
      modal-class="modal-full"
      no-enforce-focus
      hide-footer
      hide-header
    >
      <div
        v-if="barcodeFinded"
        class="mb-1"
      >
        <table
          class="table"
          style="max-width: 100%"
        >
          <tr>
            <th>Código</th>
            <td style="word-break: break-all">{{ codeIDENT.barcode }}</td>
          </tr>

          <tr>
            <th>Tipo</th>
            <td>{{ codeIDENT.type }}</td>
          </tr>

          <tr>
            <th>Vencimento</th>
            <td>{{ codeIDENT.expirationDate | moment("DD/MM/YYYY") }}</td>
          </tr>

          <tr>
            <th>Valor</th>
            <td>{{ codeIDENT.amount | currencyMask }}</td>
          </tr>

          <tr>
            <th>Banco</th>
            <td>{{ codeIDENT.banks }}</td>
          </tr>
        </table>

        <b-btn
          block
          variant="success"
          @click="utilizarBoleto"
          ><b-icon-check /> Utilizar dados coletados</b-btn
        >
        <b-btn
          block
          variant="primary"
          @click="lerBoleto"
          ><i class="fa fa-barcode" /> Repetir Leitura</b-btn
        >
      </div>
      <div
        v-else
        class="text-center m-auto"
      >
        <div
          class="w-100"
          style="background-color: rgba(0, 0, 0, 0.9)"
        >
          <video
            playsinline
            ref="cameraVideo"
            width="550"
            height="150"
          />
        </div>
        <b-spinner small /> tentando identificar dados...
        <canvas
          id="cameraCanvas"
          ref="cameraCanvas"
          aria-hidden="true"
          style="visibility: hidden"
        />
      </div>
      <b-btn
        block
        @click="fecharLeituraBoleto"
        ><b--icon-x /> Fechar</b-btn
      >
    </b-modal>
  </div>
</template>

<script>
import CustoLib from "../../../libs/CentrosDeCustoLib";
import FornecedoresLib from "../../../libs/FornecedoresLib";
import PlanoContasLib from "../../../libs/PlanoContasLib";
import ContasPagarLib from "../../../libs/ContasPagarLib";
import moment from "moment-timezone";
import FileUpload from "../../common/FileUpload.vue";
import NotaFiscalForm from "../NotaFiscal/NotaFiscalForm.vue";
import { Boleto } from "broleto";

import javascriptBarcodeReader from "javascript-barcode-reader";
import SelectFornecedor from "../../Fornecedores/SelectFornecedor.vue";
import SelectCentroCusto from "../SelectCentroCusto.vue";
import SelectPlanoContas from "../SelectPlanoContas.vue";
import SelectCarteira from "../Bancario/SelectCarteira.vue";
import SelectTipoHIstorico from "../Bancario/SelectTipoHIstorico.vue";
let defObj = {
  parcelas: 1,
  vl_total: 0,
  fornecedor_id: null,
  cod_custo: null,
  plano_id: null,
  tipo: "V",
  dt_expira: moment().add(12, "months").format("YYYY-MM-DD"),
  nao_expira: 0,
  prazo_pagamento: 0,
  tipo_calculo_parcela: "Mensal",

  dt_vencimento: moment().format("YYYY-MM-DD"),
  parcelasObj: [
    {
      parcela: 1,
      situacao: "A",
      dt_vencimento: moment().format("YYYY-MM-DD"),
      valor: "R$ 0,00",
      baixar: false,
      dt_competencia: moment().format("YYYY-MM-DD"),
      baixa: {
        data: moment().format("YYYY-MM-DD"),
        carteira_id: null,
        tipo_historico_id: 2,
        conciliado: 0,
      },
    },
  ],
  nota_fiscal: {},
  fotos: [],
  adicional: {
    pagamento: {
      tipo: "",
      obs: "",
    },
  },
  created_at: moment().format("YYYY-MM-DD HH:mm:ss"),
  dt_competencia: moment().add(1, `d`).format("YYYY-MM-DD"),
  // cod_usuario: this.$store.state.user.cod_usuario
};
export default {
  components: {
    FileUpload,
    NotaFiscalForm,
    SelectFornecedor,
    SelectCentroCusto,
    SelectPlanoContas,
    SelectCarteira,
    SelectTipoHIstorico,
  },
  props: {
    editObj: Object,
  },
  mounted() {
    // console.log("user", this.$store.state.auth.user)

    if (!this.editObj || !this.editObj.id) {
      this.obj = Object.assign({}, { ...defObj });
      this.obj.dt_competencia = moment()
        .tz("America/Sao_Paulo")
        .format("YYYY-MM-DD 20:00");
    } else {
      this.loading = true;
    }
    this.carregarTela();
  },
  data() {
    return {
      saving: false,
      tipoCalculoParcela: "Mensal",
      dt_inicial: moment().format("YYYY-MM-DD"),
      custos: [],
      planos: [],
      fornecedores: [],
      obj: Object.assign({}, { ...defObj }),
      tentaLerBoletoTimer: null,
      code: null,
      codeIDENT: null,
      barcodeFinded: false,
      loading: false,
      initialTipoHistorico: 2,
      tabParc: 0,
      loadingCalculoParcelas: false,
      loaded: false,
    };
  },
  watch: {
    "obj.dt_competencia": function () {
      console.log(123, this.obj);
      if (!this.obj.id && this.obj.dt_competencia) {
        // this.obj.dt_vencimento = moment(this.obj.dt_competencia).format(
        //   "YYYY-MM-DD"
        // );
        //this.recalcularParcelas();
      }
      if (this.obj && !this.obj.id && this.obj.tipo == "V") {
        this.obj.parcelasObj.forEach((x) => {
          if (!x.situacao || x.situacao == "A")
            x.dt_competencia = moment(this.obj.dt_competencia).format(
              "YYYY-MM-01"
            );
        });
      }
    },
    "obj.pano_id": async function () {
      console.log("obj.plano_id", this.obj.plano_id);
      if (this.obj.plano_id) {
        let find = await PlanoContasLib.getPlanoContas({
          id: this.obj.plano_id,
        });
        if (find) {
          if (find.data && find.data.length > 0) {
            this.obj.tipo_historico_id = find.data[0].tipo == 1 ? 1 : 2;
          }
        }
      }
    },
    "obj.tipo": {
      handler() {
        if (
          this.obj &&
          !this.obj.id &&
          this.obj.tipo == "V" &&
          this.obj.parcelasObj
        ) {
          this.obj.parcelasObj.forEach((x) => {
            x.dt_competencia = moment(this.obj.dt_competencia).format(
              "YYYY-MM-01"
            );
          });
        }
        // if (this.obj.tipo == "F") {
        //   // this.calcualrParcelasFixas();
        // } else {
        // }
      },
      deep: true,
    },
    "obj.tipo_calculo_parcela": {
      handler() {
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
    "obj.dt_vencimento": {
      handler() {
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
    "obj.nao_expira": {
      handler(old, n) {
        console.log(old, n);
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
    "obj.vl_total": {
      handler() {
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
    "obj.dt_expira": {
      handler() {
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
    "obj.prazo_pagamento": {
      handler() {
        if (this.obj.tipo == "F") {
          // this.calcualrParcelasFixas();
        }
      },
      deep: true,
    },
  },
  computed: {
    parcelasLista() {
      return this.obj && this.obj.parcelasObj
        ? this.obj.parcelasObj.filter((x) => {
            if (this.obj.tipo == "F") {
              if (this.tabParc == 0) {
                return x.situacao == "A";
              } else {
                return x.situacao == "T";
              }
            } else {
              return true;
            }
          })
        : [];
    },
    botaoAtualizaParcela: function () {
      let vl = this.somaParcelas();
      // console.log('calc',this.obj, this.formatValorReal(this.obj.vl_total), Math.floor(this.formatFloatToReal(this.obj.vl_total)), Math.floor(vl))
      return (
        this.obj.parcelas != this.obj.parcelasObj.length ||
        Math.floor(this.formatValorReal(this.obj.vl_total)) - Math.floor(vl) >
          2 ||
        Math.floor(this.formatValorReal(this.obj.vl_total)) - Math.floor(vl) <
          -2
      );
    },
  },
  methods: {
    async calcualrParcelasFixas() {
      console.log("calculando parcelas fixas");
      if (!this.obj || !this.obj.parcelasObj || !this.loaded) return;
      this.obj.parcelasObj = this.obj.parcelasObj.filter(
        (x) => x.situacao && x.situacao !== "A"
      );

      if (
        this.obj.tipo != "F" ||
        !this.obj.tipo_calculo_parcela ||
        !this.obj.dt_vencimento ||
        !this.obj.vl_total
      ) {
        return;
      }
      this.loadingCalculoParcelas = true;
      await this.$nextTick();
      try {
        let prazoAdd = 0;
        try {
          prazoAdd = parseInt(this.obj.prazo_pagamento);
        } catch (error) {
          console.log("erro prazo pgt", error);
          prazoAdd = 0;
        }
        console.log("prazo pgt", prazoAdd);
        // prazoAdd++;
        let qtdParcelas = 12;
        let fatorDivisao =
          this.obj.tipo_calculo_parcela == "Diaria"
            ? 1
            : this.obj.tipo_calculo_parcela == "Mensal"
            ? 30
            : this.obj.tipo_calculo_parcela == "Semanal"
            ? 7
            : this.obj.tipo_calculo_parcela == "Quinzenal"
            ? 15
            : this.obj.tipo_calculo_parcela == "Bimestral"
            ? 60
            : this.obj.tipo_calculo_parcela == "Trimestral"
            ? 90
            : this.obj.tipo_calculo_parcela == "Semestral"
            ? 180
            : this.obj.tipo_calculo_parcela == "Anual"
            ? 365
            : 30;

        if (this.obj.nao_expira !== 1) {
          let qtdDias = moment(this.obj.dt_expira).diff(
            moment(this.obj.dt_vencimento),
            "days"
          );

          qtdParcelas = Math.floor(qtdDias / fatorDivisao);
        }
        console.log("prazoAdd", prazoAdd);
        for (let i = 0; i <= qtdParcelas; i++) {
          let calcData =
            this.obj.tipo_calculo_parcela == "Mensal"
              ? moment(this.obj.dt_vencimento)
                  .add(i, "month")
                  // .add(prazoAdd, "days")

                  .format("YYYY-MM-DD")
              : moment(this.obj.dt_vencimento)
                  .add(i * fatorDivisao, "days")
                  // .add(prazoAdd, "days")
                  .format("YYYY-MM-DD");
          let calcDataVenc = moment(calcData)
            .add(prazoAdd, "days")
            .format("YYYY-MM-DD");
          this.obj.parcelasObj.push({
            parcela: i + 1,
            situacao: "A",
            dt_vencimento: calcDataVenc,
            dt_competencia: moment(calcData).format("YYYY-MM-DD"),
            valor: this.obj.vl_total,
            baixar: false,

            baixa: {
              data: calcData,
              carteira_id: null,
              tipo_historico_id: 2,
              conciliado: 0,
            },
          });
        }
      } catch (exr) {
        console.log("error", exr);
      }
      this.loadingCalculoParcelas = false;
      this.$forceUpdate();
      await this.$nextTick();
      this.$refs.tbParcelas.refresh();
    },
    async carregarTela() {
      console.log("obj", this.editObj);
      await this.carregarCentrosDeCusto();
      await this.carregarFornecedores();
      if (this.editObj && this.editObj.id) {
        await this.carregar();
      }
      this.loading = false;
      this.loaded = true;
    },
    async utilizarBoleto() {
      if (this.barcodeFinded) {
        this.fecharLeituraBoleto();
        this.obj.vl_total = this.formatFloatToReal(this.codeIDENT.amount);
        this.obj.parcelas = 1;
        this.obj.dt_vencimento = moment(this.codeIDENT.expirationDate);
        this.obj.adicional.pagamento.obs = this.codeIDENT.barcode;
        this.recalcularParcelas();

        let res = await this.$swal.fire({
          title: "Anexar foto?",
          text: "Deseja anexar uma foto do boleto / documetno?",
          icon: "question",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "Anexar Foto",
          cancelButtonText: "Não",
        });

        if (res && res.isConfirmed) {
          this.$refs.flUP.openFile();
        }
      }
    },
    async buscarBoleto() {
      try {
        var canvas = this.$refs.cameraCanvas;
        canvas.height = this.$refs.cameraVideo.videoHeight;
        canvas.width = this.$refs.cameraVideo.videoWidth;
        var context = canvas.getContext("2d");
        context.drawImage(this.$refs.cameraVideo, 0, 0);
        // console.log(javascriptBarcodeReader);
        javascriptBarcodeReader({
          image: this.$refs.cameraCanvas,
          barcode: "code-2of5",
          barcodeType: "interleaved",
        })
          .then(async (result) => {
            // console.log("tryed", result);
            this.code = result;
            try {
              this.code = result;
              let bol = new Boleto(result);
              this.codeIDENT = bol.toJSON();

              if (
                this.codeIDENT &&
                this.codeIDENT.barcode &&
                this.codeIDENT.codeType &&
                this.codeIDENT.codeType == "CODIGO DE BARRAS"
              ) {
                this.barcodeFinded = true;
                clearInterval(this.tentaLerBoletoTimer);
                this.tentaLerBoletoTimer = null;
                this.$refs.cameraVideo.srcObject.getVideoTracks()[0].stop();
                this.$refs.cameraVideo.src = "";
              } else {
                this.barcodeFinded = false;
              }
            } catch (error) {
              console.log("errored", error);
              this.codeIDENT = "error";
              this.barcodeFinded = false;
              //error;
            }
          })
          .catch((er) => {
            console.log(er);
          });
      } catch (error) {
        console.log("erro", error);
        this.fecharLeituraBoleto();
      }
    },
    async startCameraBoleto() {
      this.barcodeFinded = false;
      this.code = "";
      await this.$nextTick();
      this.$refs.cameraVideo.setAttribute("autoplay", "");
      this.$refs.cameraVideo.setAttribute("muted", "");
      this.$refs.cameraVideo.setAttribute("playsinline", "");

      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            facingMode: "environment",
            width: 600,
            height: 120,
          },
        })
        .then((mediaStream) => {
          this.$refs.cameraVideo.srcObject = mediaStream;
          this.$refs.cameraVideo.addEventListener("loadedmetadata", () => {
            console.log(123);
            this.$refs.cameraVideo.play();
            this.tentaLerBoletoTimer = setInterval(() => {
              this.buscarBoleto();
            }, 900);
          });
        })
        .catch((error) => {
          console.log("Error", error);
        });
    },
    fecharLeituraBoleto() {
      if (this.tentaLerBoletoTimer) {
        clearInterval(this.tentaLerBoletoTimer);
      }

      try {
        this.$refs.cameraVideo.srcObject.getVideoTracks()[0].stop();
      } catch (error) {
        error;
      }
      try {
        this.$refs.cameraVideo.src = "";
      } catch (error) {
        error;
      }
      try {
        this.barcodeFinded = false;
      } catch (error) {
        error;
      }
      try {
        this.tentaLerBoletoTimer = null;
      } catch (error) {
        error;
      }
      this.$bvModal.hide("modal-ler-boleto");
    },
    async lerBoleto() {
      if (this.tentaLerBoletoTimer) {
        clearInterval(this.tentaLerBoletoTimer);
      }
      this.barcodeFinded = false;
      this.codeIDENT = null;

      if (
        this.$store.state.isMobile &&
        !window.matchMedia("(orientation: landscape)").matches
      ) {
        this.$swal("Utilize o celular na horizontal!", "", "error");
        return;
      }
      this.tentaLerBoletoTimer = null;
      this.$bvModal.show("modal-ler-boleto");
      await this.$nextTick();
      this.$nextTick(() => {
        this.startCameraBoleto();
      });
    },
    async tentaLerBoleto() {},
    async carregar() {
      if (this.editObj) {
        let res = await ContasPagarLib.getCPById(this.editObj.id);
        console.log("ers", res);
        if (res && res.success) {
          this.obj = res.data;
          if (this.obj && this.obj.parcelasObj) {
            this.obj.parcelasObj = this.obj.parcelasObj.map((x) => ({
              ...x,
              _rowVariant: x.situacao == "T" ? "success" : "",
            }));
            console.log("parcelas", this.obj.parcelasObj);
            this.obj.dt_competencia = moment(
              this.obj.parcelasObj[0].dt_competencia
            ).format("YYYY-MM-DD");
          }
          //console.log("")
        }
      }
    },
    async carregarCentrosDeCusto() {
      this.custos = await CustoLib.get();
      this.planos = (await PlanoContasLib.getPlanoContas()).data;
    },
    async carregarFornecedores() {
      this.fornecedores = await FornecedoresLib.getFornecedores();
    },
    async recalcularParcelas() {
      this.obj.parcelasObj = this.obj.parcelasObj.filter(
        (x) => x.situacao && x.situacao !== "A"
      );
      console.log(
        this.obj.parcelasObj,
        await this.obj.parcelasObj.reduce((ret, vl) => {
          if (vl && vl.valor_pago) {
            ret += this.formatValorReal(vl.valor_pago);
          }
          return ret;
        }, 0)
      );

      let valor =
        this.formatValorReal(this.obj.vl_total) -
        (await this.obj.parcelasObj.reduce((ret, vl) => {
          if (vl && vl.valor_pago) {
            ret += this.formatValorReal(vl.valor_pago);
          }
          return ret;
        }, 0));
      //  console.log(valor,  this.formatFloatToReal( valor  / parcelas));
      let parcelas = this.obj.parcelas - this.obj.parcelasObj.length;
      let multiplicadorCalculoParcela = {
        Mensal: {
          qtd: 1,
          tp: "month",
        },
        Semanal: {
          qtd: 1,
          tp: "week",
        },
        Quinzenal: {
          qtd: 15,
          tp: "days",
        },
        Bimestral: {
          qtd: 2,
          tp: "months",
        },
        Trimestral: {
          qtd: 3,
          tp: "months",
        },
        Semestral: {
          qtd: 6,
          tp: "months",
        },
        Anual: {
          qtd: 1,
          tp: "year",
        },
      };
      for (let i = 1; i <= parcelas; i++) {
        // console.log(
        //   moment()
        //     .add(i - 1, "month")
        //     .format("YYYY-MM-DD"),
        //   i
        // );
        // console.log(
        //   this.tipoCalculoParcela,
        //   i,
        //   (i - 1) * multiplicadorCalculoParcela[this.tipoCalculoParcela].qtd,
        //   multiplicadorCalculoParcela[this.tipoCalculoParcela].tp,
        //   moment(this.dt_inicial).add(
        //     i - 1 * multiplicadorCalculoParcela[this.tipoCalculoParcela].qtd,
        //     multiplicadorCalculoParcela[this.tipoCalculoParcela].tp
        //   )
        // );
        this.obj.parcelasObj.push({
          parcela: this.obj.parcelasObj.some((x) => x.parcela == i) ? i + 1 : i,
          dt_vencimento: moment(this.obj.dt_vencimento)
            .add(
              (i - 1) *
                multiplicadorCalculoParcela[this.tipoCalculoParcela].qtd,
              multiplicadorCalculoParcela[this.tipoCalculoParcela].tp
            )
            .format("YYYY-MM-DD"),
          dt_competencia: moment(
            i == 1 ? this.obj.dt_competencia : this.obj.dt_vencimento
          )
            .add(
              (i - 1) *
                multiplicadorCalculoParcela[this.tipoCalculoParcela].qtd,
              multiplicadorCalculoParcela[this.tipoCalculoParcela].tp
            )
            .format("YYYY-MM-DD"),
          valor: this.formatFloatToReal(valor / parcelas),
          baixar: false,
          baixa: {
            data: moment().format("YYYY-MM-DD"),
            carteira_id: null,
            tipo_historico_id: 2,
            conciliado: 0,
          },
        });
      }
    },
    somaParcelas() {
      let vl = 0;

      for (let pc of this.obj.parcelasObj) {
        vl += this.formatValorReal(pc.valor);
      }

      return vl;
    },
    async salvar() {
      await this.$nextTick();
      if (this.saving) return;
      let err = [];
      if (this.obj.tipo == "V" && this.botaoAtualizaParcela) {
        err.push("Corrija as parcelas / valor");
      }
      if (!this.obj.fornecedor_id) {
        err.push("Selecione um fornecedor!");
      }
      if (!this.obj.cod_custo) {
        err.push("Selecione um centro de custo!");
      }
      if (!this.obj.plano_id) {
        err.push("Selecione um plano de conta!");
      }
      if (
        this.obj.tipo == "V" &&
        Math.floor(this.somaParcelas().toFixed(2)) !==
          Math.floor(this.formatValorReal(this.obj.vl_total).toFixed(2))
      ) {
        err.push(
          "O valor da soma das parcelas não é o mesmo do valor da conta!"
        );
      }
      // if (this.obj.tipo == "F" && this.obj.prazo_pagamento <= 0) {
      //   err.push("Informe o prazo de pagamento");
      // }
      if (
        this.obj.tipo == "F" &&
        this.obj.nao_expira !== 1 &&
        !this.obj.dt_expira
      ) {
        err.push("Informe a data de expiração");
      }
      if (this.obj.tipo == "F" && !this.obj.dt_vencimento) {
        err.push("Informe a data de início");
      }
      if (
        this.obj.tipo == "F" &&
        this.formatValorReal(this.obj.vl_total) <= 0
      ) {
        err.push("Informe o valor");
      }

      if (err.length > 0) {
        this.$swal({
          title: "Atenção",
          html: "<hr/>" + err.join("<hr/>"),
          icon: "error",
        });
      } else {
        this.saving = true;
        await this.$nextTick();

        try {
          this.obj.cod_usuario = this.$store.state.auth.user.cod_usuario;

          let res = await ContasPagarLib.storeContasPagar(this.obj);
          console.log(res);
          if (res && res.success) {
            this.$swal("Salvo com sucesso!", "", "success");
            this.$emit("close");
          } else {
            this.$swal("Erro ao salvar", "", "error");
          }
        } catch (error) {
          console.log("error", error);
        }

        this.saving = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
